import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    input,
    Output,
    ViewChild
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TypographyDirective } from '../../../directives/typography/typography.directive';
import {
    MatAutocomplete,
    MatAutocompleteModule,
    MatAutocompleteOrigin
} from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-base-search-input',
    standalone: true,
    imports: [
        FormsModule,
        TypographyDirective,
        MatAutocompleteModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    templateUrl: './base-search-input.component.html',
    styleUrl: './base-search-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseSearchInputComponent {
    @Output() onClickedEnter = new EventEmitter<Event>();
    matAutocomplete = input<MatAutocomplete>();
    matAutocompleteConnectedTo = input<MatAutocompleteOrigin>();
    matAutocompletePosition = input<'auto' | 'above' | 'below'>('auto');
    appearance = input<'black' | 'white'>('black');
    size = input<'default' | 'large'>('default');
    placeholder = input('');
    @HostBinding('class') get classBinding() {
        return {
            white: this.appearance() === 'white',
            large: this.size() === 'large'
        };
    }
    @ViewChild('input') inputRef!: ElementRef<HTMLInputElement>;
    @Output() onFocused = new EventEmitter<Event>();

    constructor(private control: NgControl) {}

    get value() {
        return this.control.value;
    }

    blur() {
        (this.inputRef.nativeElement as HTMLInputElement).blur();
    }
}
