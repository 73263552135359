<div class="search-wrapper">
    @defer (when matAutocomplete()) {
        <input
            #input
            (keydown.enter)="onClickedEnter.emit($event)"
            (focus)="onFocused.emit($event)"
            [appTypography]="size() === 'large' ? 'SECONDARY' : 'SMALL'"
            [matAutocomplete]="matAutocomplete()!"
            [matAutocompleteConnectedTo]="matAutocompleteConnectedTo()!"
            [matAutocompletePosition]="matAutocompletePosition()!"
            [placeholder]="placeholder() | translate"
            color="WHITE"
            [value]="value" />
        <div class="search-icon-block">
            <i
                (click)="input.focus()"
                class="
                WTicon-navbar-search{{
                    appearance() === 'black' ? '-white' : ''
                }} search-icon
            ">
            </i>
        </div>
    } @placeholder {
        <input
            #input
            (keydown.enter)="onClickedEnter.emit($event)"
            (focus)="onFocused.emit($event)"
            [appTypography]="size() === 'large' ? 'SECONDARY' : 'SMALL'"
            [placeholder]="placeholder() | translate"
            color="WHITE"
            [value]="value" />
        <div class="search-icon-block">
            <i
                (click)="input.focus()"
                class="
                WTicon-navbar-search{{
                    appearance() === 'black' ? '-white' : ''
                }} search-icon
            ">
            </i>
        </div>
    }
</div>
